<template>
    <modal ref="modalCrearImpuesto" :titulo="`${editando ? 'Actualizar' : 'Crear'} Impuesto`" tamano="modal-lg" no-aceptar adicional="Guardar" @adicional="handle()">
        <div class="row mx-0">
            <div class="col-auto px-4" />
            <ValidationObserver ref="form" class="col">
                <div class="row mx-0">
                    <div class="col">
                        <ValidationProvider v-slot="{errors}" rules="required|max:40" name="nombre del impuesto" vid="nombre">
                            <p class="pl-3 text-general f-14">Nombre del impuesto</p>
                            <el-input v-model="model.nombre" placeholder="Nombre del impuesto" class="w-100" size="small" show-word-limit maxlength="40" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-4">
                        <ValidationProvider v-slot="{errors}" rules="required|max:20" name="siglas" vid="nombre_factura">
                            <p class="pl-3 text-general f-14">Siglas</p>
                            <el-input v-model="model.nombre_factura" placeholder="Siglas" class="w-100" size="small" show-word-limit maxlength="20" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-4">
                        <ValidationProvider v-slot="{errors}" vid="porcentaje" rules="required|decimal:2|max_value:100|greaterThanZero" name="valor">
                            <p class="pl-3 text-general f-14">Valor %</p>
                            <input-miles v-model="model.porcentaje" :money-options="configComision" placeholder="%" class="w-100" style="font-size:14px;" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 mt-3">
                        <p class="pl-3 text-general f-14">Descripción</p>
                        <el-input v-model="model.descripcion" type="textarea" :rows="5" class="w-100" placeholder="Descripción" show-word-limit maxlength="500" />
                    </div>
                </div>
                <div class="row mx-0 py-3" />
                <div class="row mx-0">
                    <p class="col-12 text-general f-12 mb-3">
                        Cedis donde se puede aplicar este impuesto
                    </p>
                    <div v-for="(cedi, i) in cedis" :key="i" class="col-12 pl-5">
                        <el-checkbox v-model="cedi.checked" class="check-dark" />
                        <span class="text-general f-15 ml-3 f-500">{{ cedi.nombre }}</span>
                    </div>
                </div>
            </ValidationObserver>
            <div class="col-auto px-4" />
        </div>
    </modal>
</template>

<script>
import impuestos from '~/services/configurar/admin/impuestos/impuestos'
export default {
    data(){
        return {
            model: {
                nombre: '',
                nombre_factura: '',
                porcentaje: '',
                descripcion: '',
                cedis: [],
            },
            editando: false,
            id: 0,
            cedis: [],
            configComision: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' %',
                precision: 1,
                maxlength: 7,
                masked: false
            },
        }
    },
    methods: {
        async toggle(){
            this.limpiar()
            this.listarCedis();
            this.$refs.modalCrearImpuesto.toggle();
        },
        async editar(impuesto, cedisSelecteds){
            this.limpiar()
            await this.listarCedis();
            this.cedis = this.cedis.map(el => ({...el, checked: cedisSelecteds.includes(el.id)}))
            const model = _.cloneDeep(impuesto)
            this.model.nombre = model.nombre
            this.model.nombre_factura = model.nombre_factura
            this.model.porcentaje = model.porcentaje
            this.model.descripcion = model.descripcion
            this.id = model.id
            this.editando = true
            this.$refs.modalCrearImpuesto.toggle();
        },
        async listarCedis(){
            try {
                const { data }  = await impuestos.listarCedis()
                this.cedis = data.map(el => ({...el, checked: false}))
            } catch (error){
                this.error_catch(error)
            }
        },
        async handle(){
            if(this.editando){
                await this.actualizar()
            } else {
                await this.guardar()
            }
        },
        async actualizar(){
            try {
                let valid = await this.$refs.form.validate()
                if(!valid) return
                const cedis = this.cedis.reduce((acc, el) => {
                    if (el.checked){
                        acc.push(el.id)
                    }
                    return acc
                }, [])
                this.model.cedis = cedis
                const { data }  = await impuestos.editar(this.id, this.model)

                if (data.exito){
                    this.notificacion('', 'Actualizado correctamente', 'success')
                    this.$emit('saved')
                    this.$refs.modalCrearImpuesto.toggle();
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async guardar(){
            try {
                let valid = await this.$refs.form.validate()
                if(!valid) return
                const cedis = this.cedis.reduce((acc, el) => {
                    if (el.checked){
                        acc.push(el.id)
                    }
                    return acc
                }, [])
                this.model.cedis = cedis
                const { data }  = await impuestos.crear(this.model)

                if (data.exito){
                    this.notificacion('', 'Creado correctamente', 'success')
                    this.$emit('saved')
                    this.$refs.modalCrearImpuesto.toggle();
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        limpiar(){
            this.model = {
                nombre: '',
                nombre_factura: '',
                porcentaje: '',
                descripcion: '',
                cedis: []
            }
            this.id = 0
            this.editando = false
            this.$refs.form.reset()
        }
    }
}
</script>
